import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/services/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { Style, StyleService } from '../@vex/services/style.service';
import { ConfigName } from '../@vex/interfaces/config-name.model';

import icCursos from '@iconify/icons-fa-solid/chalkboard-teacher';
import icHome from '@iconify/icons-ic/twotone-contacts';
import icBook from '@iconify/icons-ic/book';
import icChart from '@iconify/icons-fa-solid/chart-line';
import icServices from '@iconify/icons-ic/language';
import icBlog from '@iconify/icons-ic/twotone-import-contacts';
import icBusiness from '@iconify/icons-ic/twotone-supervisor-account';
import icClients from '@iconify/icons-ic/group';

import icBServices from '@iconify/icons-ic/room-service';

import { UserGlobals } from './user_globals';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'vex';

  constructor(private configService: ConfigService,
    private styleService: StyleService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    public user_globals: UserGlobals) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    showConfigButton: false,
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.pipe(
      map(queryParamMap => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl'))),
    ).subscribe(isRtl => {
      this.document.body.dir = isRtl ? 'rtl' : 'ltr';
      this.configService.updateConfig({
        rtl: isRtl
      });
    });

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('layout'))
    ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.styleService.setStyle(queryParamMap.get('style') as Style));


    this.navigationService.items = [
      {
        type: 'subheading',
        label: 'Knowledge Base',
        children: [
          {
            type: 'link',
            label: "Ver informe",
            route: "/knowledge/informe",
            icon: icChart
          },
          {
            type: 'link',
            label: 'Publicaciones',
            route: '/knowledge/publicaciones',
            icon: icBook,
            // routerLinkActiveOptions: {
            //   exact: true
            // }
          },
          // {
          //   type: 'link',
          //   label: 'Equipo',
          //   icon: icBusiness,
          //   route: 'equipo'
          // }
        ]
      },
      // {
      //   type: 'subheading',
      //   label: 'Contenido',
      //   children: [
      //     {
      //       type: 'link',
      //       label: 'Servicios',
      //       icon: icServices,
      //       route: 'servicios'
      //     },
      //     {
      //       type: 'link',
      //       label: 'Cursos',
      //       icon: icCursos,
      //       route: 'clases'
      //     },
      //     {
      //       type: 'link',
      //       label: 'Blog',
      //       icon: icBlog,
      //       route: 'blogs'
      //     }
      //   ]
      // },
      // {
      //   type: 'subheading',
      //   label: 'Booking',
      //   children: [
      //     {
      //       type: 'link',
      //       label: 'Configuración de servicios',
      //       icon: icBServices,
      //       route: 'booking/config-service'
      //     }
      //   ]
      // },
      // {
      //   type: 'subheading',
      //   label: 'Clientes',
      //   children: [
      //     {
      //       type: 'link',
      //       label: 'Clientes',
      //       icon: icClients,
      //       route: 'clientes'
      //     }
      //   ]
      // },
      // {
      //   type: 'dropdown',
      //   label: 'Media',
      //   icon: icLiveTv,
      //   children: [
      //     {
      //       type: 'link',
      //       label: 'Últimas noticias',
      //       route: 'news-entries'
      //     },
      //     {
      //       type: 'link',
      //       label: 'Galería de imágenes',
      //       route: 'image-gallery'
      //     },
      //     {
      //       type: 'link',
      //       label: 'Banners de bienvenida',
      //       route: 'banners'
      //     }
      //   ]
      // },
      // {
      //   type: 'link',
      //   label: 'Mis clientes',
      //   icon: icSubscribers,
      //   route: 'customers'
      // }
    ]
  }

  async ngOnInit(): Promise<void> {
    this.user_globals.userIsChecked();
  }
}
