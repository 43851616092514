import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CustomLayoutComponent } from "./custom-layout/custom-layout.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "knowledge/informe",
    pathMatch: "full"
  },
  {
    path: "knowledge",
    component: CustomLayoutComponent,
    children: [
      {
        path: "informe",
        loadChildren: () =>
          import("./pages/sections/KNOWLEDGE/see-report/see-report.module").then((m) => m.SeeReportModule),
        data: {
          toolbarShadowEnabled: true,
        },
      },
      {
        path: "publicaciones",
        loadChildren: () =>
          import("./pages/sections/news/list-news/list-news.module").then((m) => m.ListNewsModule),
        data: {
          toolbarShadowEnabled: true,
        },
      },
      {
        path: "edit-post",
        loadChildren: () =>
          import("./pages/sections/news/conf-news/conf-news.module").then((m) => m.ConfNewsModule),
        data: {
          toolbarShadowEnabled: true,
        },
      },
      // {
      //   path: "edit-curso",
      //   loadChildren: () =>
      //     import("./pages/sections/clases/config-clases/config-clases.module").then((m) => m.ConfigClasesModule),
      //   data: {
      //     toolbarShadowEnabled: true,
      //   },
      // },
      // {
      //   path: "servicios",
      //   loadChildren: () =>
      //     import("./pages/sections/servicios/list-services/list-services.module").then((m) => m.ListServicesModule),
      //   data: {
      //     toolbarShadowEnabled: true,
      //   },
      // },
      // {
      //   path: "equipo",
      //   loadChildren: () =>
      //     import("./pages/sections/oficinas/list-oficinas/list-oficinas.module").then((m) => m.ListOficinasModule),
      //   data: {
      //     toolbarShadowEnabled: true,
      //   },
      // },
      // {
      //   path: "clases",
      //   loadChildren: () =>
      //     import("./pages/sections/clases/list-clases/list-clases.module").then((m) => m.ListClasesModule),
      //   data: {
      //     toolbarShadowEnabled: true,
      //   },
      // },
      // {
      //   path: "program-types",
      //   loadChildren: () =>
      //     import("./pages/sections/training/list-types/list-types.module").then((m) => m.ListTypesModule),
      //   data: {
      //     toolbarShadowEnabled: true,
      //   },
      // },
      // {
      //   path: "clientes",
      //   loadChildren: () =>
      //     import("./pages/sections/gallery/gallery/gallery-routing.module").then((m) =>m.GalleryRoutingModule),
      //   data: {
      //     toolbarShadowEnabled: true,
      //   },
      // },
      // {
      //   path: "banners",
      //   loadChildren: () =>
      //     import("./pages/sections/banners/banners-routing.module").then((m) =>m.BannersRoutingModule),
      //   data: {
      //     toolbarShadowEnabled: true,
      //   },
      // },
      // {
      //   path: "customers",
      //   loadChildren: () =>
      //     import("./pages/sections/customers/customers-list/customers-list.module").then((m) => m.CustomersListModule),
      //   data: {
      //     toolbarShadowEnabled: true,
      //   },
      // },
      // {
      //   path: "booking",
      //   children: [
      //     {
      //       path: "config-service",
      //       loadChildren: () =>
      //         import("./pages/sections/BOOKING_MODULE/config-service/config-service.module").then((m) => m.ConfigServiceModule),
      //       data: {
      //         toolbarShadowEnabled: true
      //       },
      //     }
      //   ]
      // }
    ]
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login/login.module").then((m) => m.LoginModule),
    data: {
      toolbarShadowEnabled: true
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "corrected",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
